<template>
  <div class="admin_login_app_class">
    <v-container
      class="login_main_body"
      fluid
    >
      <div class="admin_login_main">
        <div class="admin_main_row mt-115">
          <div class="row text-center">
            <div class="col-md-12">
              <img
                class="img_logo_main"
                src="@/assets/images/logo_main.png"
                alt=""
              >
              <div class="admin_login_main_title">
                {{ $t("adminLogin.title") }}
              </div>
            </div>
          </div>
          <div class="admin_login_main_body">
            <div id="app">
              <v-form
                ref="form"
                v-model="validRegister"
                lazy-validation
              >
                <v-snackbar
                  v-model="snackbar.visible"
                  :color="snackbar.color"
                  :multi-line="snackbar.mode === 'multi-line'"
                  :timeout="snackbar.timeout"
                  :top="snackbar.position === 'top'"
                  rounded="pill"
                  width="877.27px"
                >
                  <v-row
                    class="pr-4"
                    align="center"
                  >
                    <v-icon
                      class="pr-3"
                      dark
                      large
                    >
                      {{ snackbar.icon }}
                    </v-icon>
                    <v-row column>
                      <div>{{ $t(snackbar.text) }}</div>
                    </v-row>
                  </v-row>
                  <v-btn
                    v-if="snackbar.timeout === 0"
                    icon
                    @click="snackbar.visible = false"
                  >
                    <v-icon>clear</v-icon>
                  </v-btn>
                </v-snackbar>
                <v-container
                  class="login_main_body login_main_body_row_bottom"
                  fluid
                >
                  <v-row class="login_form_row">
                    <v-col>
                      <v-text-field
                        v-model="user.email"
                        class="login_textfield_label"
                        :rules="emailRules"
                        :label="$t('adminLogin.id')"
                        :placeholder="$t('adminLogin.idPlaceholder')"
                        required
                        filled
                        rounded
                        dense
                      />
                      <!-- <span class="ms-3 span_validation_msg">*아이디는 이메일 형식이여야 합니다.</span> -->
                    </v-col>
                  </v-row>
                  <v-row class="login_form_row2">
                    <v-col>
                      <v-text-field
                        v-model="user.password"
                        class="login_textfield_label"
                        :label="$t('adminLogin.password')"
                        :placeholder="$t('adminLogin.passwordPlaceholder')"
                        type="password"
                        :rules="passwordRules"
                        required
                        filled
                        rounded
                        dense
                        @keyup.enter="login"
                      />
                    </v-col>
                  </v-row>
                  <!-- <v-row class="login_form_row">
                                            <v-col class="text-end">
                                                <label for="">find ID | Forgot your password</label>
                                            </v-col>
                                        </v-row> -->
                  <!-- <p style="color: #6e6e6e">
                    {{ $t("adminLogin.idpassLostInstructions") }}
                  </p> -->
                  <br>
                  <v-row class="login_form_row">
                    <v-col>
                      <v-btn
                        class="login_button_down"
                        type="submit"
                        rounded
                        @click="login"
                      >
                        {{ $t("adminLogin.login") }}
                      </v-btn>
                    </v-col>
                  </v-row>
                  <!-- <v-row class="login_form_row">
                                            <v-col>
                                                <v-btn class="login_button_down_button" rounded>Sign Up</v-btn>
                                            </v-col>
                                        </v-row> -->
                </v-container>
              </v-form>
            </div>
          </div>
        </div>
      </div>
    </v-container>
    <!-- Removed coz Qa Suggestion
        <footer class="main_footer mt-80 bg-light text-center text-lg-start">
            <div class="text-start p-2">
                <img class="footer_logo_main ms-3" src="@/assets/images/footer_logo.png" alt="">
            </div>
        </footer> -->
  </div>
</template>

<script>
// import '@/assets/css/bootstrap.min.css'
  import '@/assets/css/style.css'
  import '@/assets/jquery/jquery.min.js'
  // import '@/assets/js/bootstrap.bundle.js'
  import '@/assets/js/vue.js'
  import '@/assets/js/vuetify.js'
  import '@/assets/css/vuetify.min.css'
  import i18n from '@/lang/lang'
  import MemberDataService from '@/services/MemberDataService'
  export default {
    name: 'LoginComponent',
    data () {
      return {
        user: {
          id: null,
          email: '',
          password: '',
        },
        toggle: true,
        submitted: 'register',
        valid: true,
        pwd: '',
        dialog: false,
        dialogTitle: '',
        dialogText: '',
        dialogButtonText: '',
        // Terms
        buttons: [
          {
            color: 'warning',
            title: 'Warning',
            type: 'warning',
          },
        ],
        snackbar: {
          color: null,
          icon: null,
          mode: null,
          position: 'top',
          text: null,
          timeout: 2500,
          title: null,
          visible: false,
        },

        validRegister: true,
      }
    },
    computed: {
      emailRules () {
        // return [v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || this.$t('adminLogin.emailRulesMessage')];
        return [
          (v) =>
            !v ||
            // /^[-a-zA-Z0-9_.]+@[-a-zA-Z0-9]+\.[a-zA-Z]{2,4}$/.test(v) ||
            /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
            this.$t('adminLogin.emailRulesMessage'),
        ]
      },
      passwordRules () {
        return [
          (v) =>
            !v ||
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,50}$/.test(
              v,
            ) ||
            this.$t('adminLogin.passwordRulesMessage'),
        ]
      },
    },
    methods: {
      login (e) {
        e.preventDefault()
        this.$refs.form.validate()
        if (this.user.email == '' || this.user.password == '') {
          if (this.user.email == '') {
            this.SnackbarShow('email')
            return false
          } else if (this.user.password == '') {
            this.SnackbarShow('password')
            return false
          }
        }

        var data = {
          username: this.user.email,
          password: this.user.password,
        // password: this.user.password.split("").reverse().join(""),
        }
        console.log(data)
        if (this.$refs.form.validate()) {
          MemberDataService.validateAdminLogin(data)
            .then((response) => {
              console.log(response.headers)
              switch (response.data) {
                case 'Logged In Successful':
                  localStorage.setItem('userInfo', data.username)
                  // this.$router.push("/app/dashboard/members-list")
                  this.$router.push('/MembersList')
                  break
                case 'Incorrect username or password':
                  this.SnackbarShow('Invalid ID.')
                  break
              }
            })
            .catch((e) => {
              console.log(e)
            })
        }
      },

      SnackbarShow (type) {
        switch (type) {
          case 'email':
            this.snackbar = {
              color: '#FF5252',
              icon: 'mdi-alert-circle-outline',
              mode: 'multi-line',
              position: 'top',
              timeout: 2000,
              title: 'Warning',
              text: 'adminLogin.pleaseEnterID',
              visible: true,
            }
            break
          case 'password':
            this.snackbar = {
              color: '#FF5252',
              icon: 'mdi-alert-circle-outline',
              mode: 'multi-line',
              position: 'top',
              timeout: 2000,
              title: 'Warning',
              text: 'adminLogin.pleaseEnterPassword',
              visible: true,
            }
            break
          case 'Invalid ID.':
            this.snackbar = {
              color: '#FF5252',
              icon: 'mdi-alert-circle-outline',
              mode: 'multi-line',
              position: 'top',
              timeout: 2000,
              title: 'Warning',
              text: 'adminLogin.invalidIdPassword',
              visible: true,
            }
            break
        }
      },
    },
  }
</script>

<style lang="scss">
.v-application .primary--text {
  color: #828d99 !important;
}

.login_form_row .v-input__slot {
  min-height: 52px !important;
}

.login_form_row .v-input__slot input {
  margin-top: 10px !important;
}

.login_form_row2 .v-input__slot {
  width: 500px;
  min-height: 52px !important;
}

.login_form_row2 .v-input__slot input {
  margin-top: 10px !important;
}

.login_main_body_row_bottom input {
  margin-top: 14px !important;
}

.submit-form {
  max-width: 500px;
}

.pre-formatted {
  white-space: pre;
}

.main {
  font-family: "Noto Sans" !important;
}

.v-application {
  font-family: "Noto Sans" !important;
}

.admin_login_main {
  display: flex;
  flex-wrap: wrap;
  flex: 1 1 auto;
  align-items: center !important;
  justify-content: center !important;
  // margin: 0px -12px !important;
}

.admin_login_main_title {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  color: #54565b;
  font-size: 20px;
  margin-bottom: 20px !important;
}

.admin_login_main_body {
  margin: 0 !important;
  margin-top: 30px !important;
}

.mt-30 {
  margin-top: 30px !important;
}

.mt-115 {
  margin-top: 115px !important;
}

body {
  background-color: white !important;
}

.main_footer {
  background-color: #f8f9fa !important;
}

.p-2 {
  padding: 0.5rem !important;
}

@media only screen and (max-width: 425px) {
  .login_form_row2 {
    margin-bottom: 22px !important;
  }
  .login_form_row2 .v-input__slot {
    width: unset;
  }
  div.admin_login_main_title {
    font-size: 16px;
  }
}
@media only screen and (max-width: 768px) {
  .admin_login_app_class {
    padding: 20px !important;
  }
}

// .v-snack {
//     bottom: 0 !important;
//     display: flex !important;
//     font-size: .875rem !important;
//     justify-content: center !important;
//     left: 0 !important;
//     pointer-events: none !important;
//     right: 0 !important;
//     top: 0 !important;
//     width: 100% !important;
// }
</style>
